import React from 'react'
import PropTypes from 'prop-types'

import Listing from '../Listing'
import * as S from './styled'

const LatestPosts = ({ data, posts }) => {
  const { news_title: title, news_description: description } = data

  return (
    <S.Wrapper>
      <S.InnerWrapper>
        <S.Row>
          <S.Col>
            <S.Title>{title}</S.Title>
            <S.Text>{description}</S.Text>
          </S.Col>
        </S.Row>
        <S.Row>
          <Listing posts={posts} />
        </S.Row>
      </S.InnerWrapper>
    </S.Wrapper>
  )
}

LatestPosts.propTypes = {
  data: PropTypes.shape({}).isRequired,
  posts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}
export default LatestPosts
