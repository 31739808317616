import styled from '@emotion/styled'

import { Row as DefaultRow, Col as DefaultCol } from '../UI/Grid'

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 100px 16px 48px;
  @media (max-width: ${({ theme }) => theme.breakpoints.s}) {
    padding: 0 16px;
  }
`

export const InnerWrapper = styled.div`
  width: 100%;
  max-width: 1057px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`

export const Title = styled.h2`
  color: ${({ theme }) => theme.colors.black};
`

export const Text = styled.p`
  padding: 16px 0;
  color: ${({ theme }) => theme.colors.black};
`

export const Row = styled(DefaultRow)`
  padding: 0;
  justify-content: space-between;
`

export const Col = styled(DefaultCol)`
  padding-top: 16px;
  max-width: 50%;
`
