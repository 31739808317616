import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import SEO from '../components/SEO'
import HeroWithImage from '../components/shared/HeroWithImage'
import LittersPage from '../components/LittersPage'
import LatestNews from '../components/LatestPosts'
import i18n from '../../config/i18n'

const Litters = ({ data: { litters, posts }, pageContext: { locale }, location }) => {
  const { data } = litters
  const { hero_title: heroTitle, hero_image: heroImage } = data

  return (
    <>
      <SEO pathname={location.pathname} locale={locale} banner={heroImage.url} title={i18n[locale].seo.litters.title} />
      <HeroWithImage src={heroImage.url} title={heroTitle} />
      <LittersPage data={data} />
      <LatestNews data={data} posts={posts.edges} />
    </>
  )
}

export default Litters

Litters.propTypes = {
  data: PropTypes.shape({
    litters: PropTypes.object.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}

export const pageQuery = graphql`
  query littersQuery($locale: String!) {
    posts: allPrismicPost(sort: { fields: [data___date], order: DESC }, filter: { lang: { eq: "pt-pt" } }, limit: 2) {
      edges {
        node {
          uid
          data {
            image {
              localFile {
                childImageSharp {
                  fluid {
                    src
                  }
                }
              }
            }
            description
            title {
              text
            }
            date(formatString: "DD.MM.YYYY")
            categories {
              category {
                document {
                  data {
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
    litters: prismicLittersPage(lang: { eq: $locale }) {
      data {
        hero_title
        hero_image {
          url
        }
        main_title
        main_text {
          html
        }
        news_title
        news_description
        litters {
          litter {
            uid
            document {
              data {
                father {
                  document {
                    data {
                      image {
                        url
                      }
                      name
                    }
                  }
                }
                mother {
                  document {
                    data {
                      image {
                        url
                      }
                      name
                    }
                  }
                }
                description
                date_of_birth
                birth_prediction
                origin
                kittens {
                  image {
                    url
                  }
                  name
                  description1
                }
              }
            }
          }
        }
      }
    }
  }
`
