import styled from '@emotion/styled'
import { Link as GatsbyLink } from 'gatsby'
import { FacebookShareButton } from 'react-share'
import { Col as DefaultCol, Row as DefaultRow } from '../UI/Grid'
import { Button as DefaultButton } from '../UI/Buttons'

export const Wrapper = styled.section`
  display: flex;
  justify-content: center;
  padding: 64px 16px;
  width: 100%;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    padding: 48px 16px;
  }
`

export const InnerWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
`
export const Title = styled.h2`
  font-size: 2rem;
  line-height: 1.4;

  font-weight: bold;
  color: ${({ theme }) => theme.colors.black};

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    font-size: 1.4rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    font-size: 1.25rem;
  }
`

export const Text = styled.div`
  font-size: 0.9rem;
  color: ${({ theme }) => theme.colors.black};
  line-height: 2rem;

  padding: 32px 0;
`

export const Row = styled(DefaultRow)`
  justify-content: flex-start;
  align-items: center;
  @media (max-width: ${({ theme }) => theme.breakpoints.s}) {
    flex-direction: row;
  }
`

export const Col = styled(DefaultCol)`
  margin-top: 16px;
  width: calc(100% / 2);
`

export const InnerCol = styled(DefaultCol)`
  align-self: center;
`

export const ThumbCircle = styled.div`
  border-radius: 50%;
  height: 80px;
  width: 80px;
  background-image: url(${({ src }) => src});
  background-position: center;
  background-size: cover;
  margin-right: 16px;
  margin-bottom: 8px;
`

export const ParentTitle = styled.p`
  font-size: 1.3rem;
  font-weight: bold;
  padding: 0;
  color: ${({ theme }) => theme.colors.orange};
`

export const Card = styled.div`
  background: ${({ theme }) => theme.colors.bg};
  border: 1px solid #ecebf5;
  box-shadow: 0 2px 16px 0 rgba(21, 30, 41, 0.06);
  border-radius: 4px;

  padding: 20px;
  margin-top: 24px;

  @media (max-width: ${({ theme }) => theme.breakpoints.l}) {
    max-width: 100%;
  }
`

export const DescriptionText = styled.p`
  font-size: 0.9rem;
  color: ${({ theme }) => theme.colors.black};
  line-height: 2rem;

  padding: 32px 0;
`
export const CardText = styled(DescriptionText)`
  padding: 0;
`

export const Label = styled.p`
  padding: 8px 0 0 0;
  color: ${({ theme }) => theme.colors.orange};
`

export const Button = styled(DefaultButton)`
  margin-top: 16px;
  width: 100%;

  justify-content: center;
`

export const Link = styled(GatsbyLink)`
  display: block;
  margin-right: 16px;
  width: 100%;
  max-width: 212px;
`

export const FacebookShare = styled(FacebookShareButton)`
  margin-top: 16px;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
  max-width: 32px;
  :hover {
    opacity: 0.6;
  }
  :focus {
    outline: 0;
  }
`
