import React from 'react'
import PropTypes from 'prop-types'
import { FacebookIcon } from 'react-share'
import { Location } from '@reach/router'
import moment from 'moment'

import { LocaleContext } from '../Layout'
import * as S from './styled'

const renderLitters = ({ i18n, litters, isPt, location }) => {
  if (litters.length === 1) {
    const {
      litter: { uid },
    } = litters[0]

    if (uid === 'dummy') {
      return (
        <S.Row>
          <S.ParentTitle>{i18n.noLitters}</S.ParentTitle>
        </S.Row>
      )
    }
  }
  return litters.map(litter => {
    const { data: dataLitter } = litter.litter.document[0]
    const { uid } = litter.litter
    if (uid === 'dummy') {
      return null
    }
    const { father, mother, date_of_birth: dob, description, birth_prediction: prediction } = dataLitter
    const {
      data: {
        name: nameFather,
        image: { url: imageFather },
      },
    } = father.document[0]
    const {
      data: {
        name: nameMother,
        image: { url: imageMother },
      },
    } = mother.document[0]

    const isFuture = moment(dob).isAfter()
    return (
      <S.Card key={uid}>
        <S.Row>
          <S.Col>
            <S.Row>
              <S.InnerCol>
                <S.ThumbCircle src={imageFather} />
              </S.InnerCol>
              <S.InnerCol>
                <S.ParentTitle>{i18n.father}</S.ParentTitle>
                <S.CardText>{nameFather}</S.CardText>
              </S.InnerCol>
            </S.Row>
          </S.Col>
          <S.Col>
            <S.Row>
              <S.InnerCol>
                <S.ThumbCircle src={imageMother} />
              </S.InnerCol>
              <S.InnerCol>
                <S.ParentTitle>{i18n.mother}</S.ParentTitle>
                <S.CardText>{nameMother}</S.CardText>
              </S.InnerCol>
            </S.Row>
          </S.Col>
        </S.Row>
        <S.Row>
          <S.InnerCol>
            <S.DescriptionText>{description}</S.DescriptionText>
            <S.Label>{i18n.dob}</S.Label>
            <S.CardText>{isFuture ? prediction : dob}</S.CardText>
            {!isFuture && dob && (
              <S.Row>
                <S.Link to={isPt ? `/${uid}` : `/en/${uid}`}>
                  <S.Button>{i18n.seeLitter}</S.Button>
                </S.Link>
                <S.FacebookShare
                  quote={description}
                  url={isPt ? `${location.origin}/${uid}` : `${location.origin}/en/${uid}`}
                >
                  <FacebookIcon size={32} round iconBgStyle={{ fill: 'black' }} />
                </S.FacebookShare>
              </S.Row>
            )}
          </S.InnerCol>
        </S.Row>
      </S.Card>
    )
  })
}

const LittersPage = ({ data }) => {
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]
  const isPt = lang.locale === 'pt-pt'

  const { main_title: title, main_text: text, litters } = data

  return (
    <Location>
      {({ location }) => (
        <S.Wrapper>
          <S.InnerWrapper>
            <S.Title>{title}</S.Title>
            <S.Row>
              <S.Text dangerouslySetInnerHTML={{ __html: text.html }} />
            </S.Row>
            {renderLitters({ litters, i18n, isPt, location })}
          </S.InnerWrapper>
        </S.Wrapper>
      )}
    </Location>
  )
}

LittersPage.propTypes = {
  data: PropTypes.shape({}).isRequired,
}
export default LittersPage
